@import '../../utils/colors.scss';

.promocode {
    display: flex;
    flex-direction: column;
    gap: .8rem;
    &__label {
        color: $backgroundGreen;
        font-weight: 450;
        font-size: 1rem;
    }
    &__inputs {
        display: flex;
        flex-direction: row;
        gap: 5px;

        &--input {
            background-color: rgb(186, 186, 186);
            border: 0;
            padding: 10px;
            border-radius: 10px;
            font-size: 1rem;
            color: $backgroundGreen;
        }
        &--button {
            background-color: $backgroundGreen;
            border: 0;
            padding: 10px;
            border-radius: 10px;
            font-size: 1rem;
            color: $backgroundWhite;
            cursor: pointer;
        }
    }
    &__error {
        color: $backgroundGreen;
        font-size: .85rem;
        
    }
}