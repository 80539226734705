.customer-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    &__header {
        font-size: 1.1rem;
        font-weight: 400;
    }
    & span {
        font-size: 0.9rem;
    }
}