@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.submenu-item {
    width: 100%;
    margin: .3rem 0;
    &__header {
        color: $backgroundGreen;
        font-size: 1.1rem;
        margin: 10px 0 20px;
        display: none;
    }
    &__products {
        // display: flex;
        // flex-direction: row;
        // flex-wrap: wrap;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }
}

@media (max-width: $mobileWidth) {
    .submenu-item {
        &__products {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;
            .product-card {
                width: 100%;
            }
        }   
    }
}

