@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.cart-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
    &__image {
        width: 100px;
        // height: fit-content;
        img {
            width: 100%;
            height: auto;
        }
    }

    &__name {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        p {
            color: $backgroundGreen;
            font-size: 1rem;
            margin-bottom: 10px;
        }
        small {
            color: gray;
            font-size: 0.95rem;
        }
    }

    &__counter {
        // width: 100px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        color: $backgroundGreen;
        gap: .9rem;
        span {
            white-space: nowrap;
        }
        &--btn-counter {
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: $backgroundGreen;
            color: $backgroundWhite;
            font-size: 1rem;
            border: 0;
            cursor: pointer;
            &.--none {
                opacity: 0;
            }
        }
    }
    &__price {
        color: $backgroundGreen;
        font-size: 1.3rem;
        align-self: center;
        text-align: end;
        width: 150px;

    }
}

@media (max-width: $mobileWidth) {
    .cart-item__counter--btn-counter {
        width: 1.2rem;
        height: 1.2rem;
    }   
    .cart-item__price {
        font-size: 1rem;
    } 
    .cart-item__image {
        height: auto;
    }
}