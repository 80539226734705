@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.banners-carousel {
    // background-color: bisque;
    width: 100%;
    
    &__arrow {
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 0 .3rem;
        width: 1.5rem;
        height: 1.5rem;
       
        &::before {
            display: none;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
    &__slide {
        // width: 30vw;
        // height: 100px;
        padding: 0 .2rem;
        cursor: pointer;
        outline: none;
        img {
            width: 100%;
            // height: auto;

            object-fit: cover;
            // width: 20rem;
            // height: 12rem;
            aspect-ratio: 16 / 6;

        }
    }
}

.banner-modal {
    background-color: $backgroundWhite;
    width: 40vw;
    min-width: 500px;
    max-width: 1500px;
    border-radius: 10px;
    overflow: hidden;
    &__header {
        width: 100%;
        height: auto;
        position: relative;
        img {
            width: 100%;
        }
        &--close {
            border: none;
            cursor: pointer;
            background-color: rgba(0,0,0,0);
            color: $backgroundWhite;
            position: absolute;
            top: 20px;
            font-size: 1.5rem;
            right: 20px;
        }
    }
    &__content {
        width: 100%;
        // height: 20vh;
        padding: 15px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        &--text>div {
            color: $backgroundGreen;
            white-space: pre-line;
        }
        &--link {
            background-color: $backgroundGreen;
            color: white;
            font-size: 1rem;
            padding: 0.5rem;
            width: 100px;
            align-self: center;
            justify-self: flex-end;
            border-radius: 8px;
        }
    }
}

@media (max-width: $mobileWidth) {
    .banners-carousel {
        width: 100%;
    }
    .banner-modal {
        width: 80vw;
        // max-width: max-content;
        min-width: min-content;
    }
}