@import '../../utils/sizes.scss';
.delivery {
    max-width: $maxContentWidth;
    width: 70%;
    height: 100%;
    margin: 0 auto;
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "col1 col2"
                         "map map";
    gap: 1rem;
    &__col {
        h3 {
            font-size: 1.75rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
            line-height: 1.2;
        }
        p {
            margin-bottom: 1rem;
        }
    }
    &__map {
        grid-area: map;
    }
}

@media (max-width: 1100px) {
    .delivery {
        grid-template-columns: 1fr;
        grid-template-areas: "col1"
                             "col2"
                             "map";
        width: 100%;
        padding: 1rem;
    }
}