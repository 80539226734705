@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.menu-header {
    width: 100vw;
    // height: 15vh;
    max-height: 200px;
    min-height: 100px;
    background-color: $backgroundGreen;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 3rem;
    margin-bottom: 20px;
    position: relative;

    &__arrow {
        width: 100%;
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 1rem;
        left: 0;
        img {
            transform: rotate(-90deg);
            width: .6rem;
            height: auto;
        }
    }
    &__inner {
        display: flex;
        flex-direction: column;
        max-width: $maxContentWidth;
        width: 70%;
        padding: 0 2rem;
        gap: 1rem;
    }
   
    &.--mobile {
        .menu-header__inner, .menu-header__info {
            gap: 1rem;
        }
    }

    &__name {
        color: $backgroundWhite;
        font-size: 1.3rem;
        font-weight: 600;
        text-align: start;
    }

    &__info {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 35px;

        &--icon {
            width: auto;
            height: 100%;
            // margin-right: 10px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            img {
                height: 100%;
                max-height: 30px;
                width: auto;
            }
        }

        &--text {
            color: $backgroundWhite;
            font-size: .8rem;
            font-weight: 450;
            display: flex;
            justify-content: center;
            align-items: center;
            a {
                color: inherit;
                font-size: inherit;
                font-weight: inherit;
            }
        } 

        &--phone, &--address, &--price, &--schedule {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 50%;
            gap: 10px;
        }
        &--schedule {
            & div:last-child {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

@media (max-width: $mobileWidth) {
    .menu-header {
        height: 300px;
        justify-content: center;
        align-items: center;
        // padding: 20px;
        max-height: none;
        display: block;
        transition: height .2s ease-out;
        
        &__inner {
            width: 100%;
            padding: 0;
        }

        &.--hidden {
            height: 50px;
            overflow: hidden;
            // box-shadow: inset 0px -5px 40px 10px rgba(0, 66, 23, 0.23);
        }
        &__name {
            margin-bottom: 1rem;
            text-align: center;
        }
        &__info {
            flex-direction: column;
            &--icon {
                width: 20px;
                height: auto;
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    height: auto;
                }
            }
            &--phone, &--address, &--price, &--schedule {
                display: grid;
                grid-template-columns: 30px 200px;
                height: 30px;
            }
        }
    }
}
