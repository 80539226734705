@import '../../utils/colors.scss';
.concept-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 80px;
    max-height: 80px;
    min-height: 40px;
    gap: 20px;
    // margin-bottom: 15px;
    &__img {
        // width: auto;
        // height: 10rem;
        img {
            width: auto;
            height: 5rem;
        }
    }
    &__info {
        display: flex;
        flex-direction: column;
        gap: 7px;
        // justify-content: space-around;

        &--name {
            color: $backgroundGreen;
            font-size: 1rem;
        }
        &--address {
            color: gray;
            font-size: .95rem;
        }
    }
}