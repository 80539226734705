@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.menu-page {
    // width: 100%;
    // max-width: 1400px;
    max-width: $maxContentWidth;

    width: 70%;
    height: 100%;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    // padding: 1% 0;
    .banners-carousel {
        // max-height: 10rem;

        img {
            // max-height: 10rem;
            // max-width: 300px;
            max-width: 30vw;
            // width: auto;
            margin: 0 auto;
            aspect-ratio: 16 / 9;
        }

    }
    &__ad {
        width: 100%;
        height: auto;
        // width: auto;
        // height: 50vh;
        margin-bottom: 50px;
    }

    &__error-info {
        font-size: 1rem;
        color: black;
        background-color: #CFCFCF;
        width: 100%;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        position: relative;

        &--close {
            font-size: .8rem;
            position: absolute;
            top: .5rem;
            right: .5rem;
            cursor: pointer;
            width: .7rem;
            height: .7rem;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

}

@media (max-width: $mobileWidth) {
    .menu-page {
        width: 100%;
        padding: 0;
        &__ad {
            width: 100%;
            height: auto;
        }
        .banners-carousel {
            max-height: none;
    
            img {
                max-height: none;
                max-width: none;
                // width: auto;
                // margin: 0 auto;
            }
    
        }
    }
}