@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';

.register-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    &__email {
        width: 100%;
        input {
            width: 100%;
        }
    }
    &__header {
        color: $backgroundGreen;
        font-weight: 450;
        font-size: 130%;
        width: 100%;
        margin: 20px 0;
    }

    &__desc {
        color: gray;
        font-size: 90%;
        width: 100%;
        margin-bottom: 10px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        &-fio {
            display: flex;
            flex-direction: row;
            gap: 10px;

            
        }
       
        input {
            border: 0;
            padding: 10px;
            border-radius: 8px;
            color: $backgroundGreen;
        }
        &-other-info {
            display: flex;
            flex-direction: row;
            gap: 10px;
            label {
                color: gray;
                font-size: 90%;
                margin-bottom: 5px;
            }
            .input-dob {
                display: flex;
                flex-direction: column;
                
            }
            .input-sex {
                display: flex;
                flex-direction: column;

                &>div {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                }
            }
           
        }
        &-btn-submit {
            border: 0;
            // width: 100px;
            width: fit-content;
            padding: 10px;
            border-radius: 8px;
            color: $backgroundWhite;
            background-color: $backgroundGreen;
            cursor: pointer;
        }
    }
}

@media (max-width: $mobileWidth) {
    .register-form {
        padding: 0 10px;
    }
    .register-form__content {
        width: 100%;
        &-fio {
            flex-direction: column;
        }
        &-other-info {
            flex-direction: column;
        }
        &-btn-submit {
            width: 100%;
        }
    }
}
