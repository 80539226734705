@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.main-page {
    width: 70%;
    // max-width: 1400px;
    max-width: $maxContentWidth;
    // width: 73%;
    height: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    padding-top: 1rem;
    .banners-carousel__slide {
        padding: 0;
    }
    // .banners-carousel {
    //     max-height: 25rem;

    //     img {
    //         max-height: 25rem;
    //         // max-width: 200px;
    //         // width: auto;
    //         margin: 0 auto;
    //     }

    // }
    &__ad {
        width: 100%;
        // height: auto;
        // // width: auto;
        // // height: 50vh;
        // margin-bottom: 50px;

        // display: flex;
        // justify-content: center;
        // align-items: center;

        & img {
            width: 100%;
            height: auto;
        }
    }

    &__delivery-cards {
        width: 100%;
        // display: flex;
        // flex-direction: row;
        // justify-content: space-between;
        // margin-bottom: 50px;
        gap: 2rem;
        // display: flex;
        // flex-direction: row;
        display: grid;
        grid-template-columns: 1fr 1fr;
        
        align-items: center;
        padding: 0;
        margin: 2rem auto 0;
    }
}

@media (max-width: $mobileWidth) {
    .main-page {
        width: 100%;
        padding: 0;
        // &__ad {
        //     width: 100%;
        //     height: auto;
        // }
        .banners-carousel {
            max-height: none;
    
            img {
                max-height: none;
                max-width: none;
                // width: auto;
                // margin: 0 auto;
            }
    
        }
        &__delivery-cards {
            display: flex;
            flex-direction: column;
            width: 90%;
            gap: 20px;
            margin-bottom: 20px;
        }
    }
}
