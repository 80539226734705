@import '../../utils/colors.scss';

.mini-cart {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background-color: $backgroundMilk;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    &__content {
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &-item {
            display: flex;
            flex-direction: row;

            img {
                width: 3rem;
                height: auto;
                margin-right: 20px;
                font-size: .5rem;
            }

            &--title {
                font-weight: 500;
                p {
                    color: $backgroundGreen;
                    font-size: 1rem;
                }
                small {
                    color: gray;
                    font-size: .9rem;
                }
            }
        }

        &-summary {
            display: flex;
            flex-direction: row;
            align-items: center;
            a {
                background-color: $backgroundGreen;
                color: $backgroundWhite;
                border-radius: 20px;
                padding: 1.3rem;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1rem;
                font-weight: 400;
                margin-right: 1rem;
            }
            p {
                color: $backgroundGreen;
                font-size: 1rem;
                font-weight: 450;
            }
        }
    }
}


.mini-cart-mobile {
    width: 100%;
    height: 40px;
    position: fixed;
    bottom: 20px;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
    &__btn {
        width: 80%;
        background-color: $backgroundGreen;
        color: $backgroundWhite;
        font-size: 1.3rem;
        display: flex;
        justify-content: space-around;
        border-radius: 20px;
        align-items: center;
        // border: 2px solid $gold;
    }
    
    &__to-top {
        width: 40px;
        height: 40px;
        // border: 2px solid $backgroundWhite;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $backgroundGreen;
        border-radius: 50%;
        padding: .9rem;
        transform: rotate(90deg);
        img {
            width: 100%;
            height: auto;
        }
    }
}