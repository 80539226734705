@import '../../utils/colors.scss';

.order-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    &__header {
        color: $backgroundGreen;
        font-weight: 450;
        font-size: 1.2rem;
        width: 100%;
        margin: 1.1rem 0;
    }

    &__content {
        width: 100%;
        background-color: rgb(199, 199, 199);
        border-radius: 10px;
        padding: 10px;

        &-item {
            display: grid;
            grid-template-columns: 2.5fr 1fr 1fr;
            gap: 5px;
            margin-bottom: 10px;
            &--info {
                display: flex;
                flex-direction: column;
                span {
                    color: $backgroundGreen;
                    font-size: 1rem;
                    font-weight: 500;
                }
                small {
                    color: rgb(109, 109, 109);
                    font-size: .95rem;
                }
            }
            &--price {
                min-width: 50px;
                text-align: end;
            }
        }

        &-total {
            border-top: 2px solid $backgroundGreen;
            text-align: end;
            font-size: 1.2rem;
            color: $backgroundGreen;
            padding-top: 10px;
            font-weight: 500;
        }
    }
}