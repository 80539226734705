@import '../../utils/colors.scss';

.order-phone {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__header {
        color: $backgroundGreen;
        font-weight: 450;
        font-size: 1.2rem;
        width: 100%;
        margin: 20px 0 10px;
    }

    &__desc {
        color: gray;
        font-size: 1rem;
        width: 100%;
        margin-bottom: 10px;
    }
}