@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.menu-items {
    width: 100%;
    scroll-margin-top: 50px;
    &__header {
        color: $backgroundGreen;
        font-weight: 500;
        font-size: 1.7rem;
        margin: .5rem 0;
    }
    &__desc, &__not-found{
        color: $backgroundGreen;
        font-weight: 500;
        font-size: 1rem;
    }

    &__skeleton {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
        margin-bottom: 1rem;
        &>div {
            background-color: $backgroundGray;
            width: 100%;
            aspect-ratio: 3 / 4;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 1rem;

            &>div {
                background-color: rgb(184, 184, 184);
                width: 100%;
                height: 2rem;
                position: relative;
                overflow: hidden;
                border-radius: 10px;
                &:first-child {
                    height: auto;
                    aspect-ratio: 1;
                }

                &::before {
                    content: '';
                    width: 1rem;
                    position: absolute;
                    height: 200%;
                    top: -10%;
                    
                    background-color: white;
                    rotate: 10deg;
                    filter: blur(10px);
                    animation: slide 1s linear infinite;

                    @keyframes slide {
                        from {
                            left: -20%;
                        }
                        to {
                            left: 150%;
                        }
                    }
                }
            }
        }
    }
    
}

@media (max-width: $mobileWidth) {
    .menu-items {
        padding: 0 20px;
        &__skeleton {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}