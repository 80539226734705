@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.thanks-for-order {
    max-width: $maxContentWidth;

    width: 70%;
    height: 100%;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    // align-items: center;
    gap: 1rem;
    h1 {
        font-size: 1.2rem;
        color: $backgroundGreen;
    }
    &__total {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        span {
            font-size: 1.4rem;
            color: $backgroundGreen;
        }
       
    }
    &__cart {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .cart-item__counter {
            span {
                white-space: nowrap;
            }
        }
    }
    &__back {
        width: fit-content;
        border-radius: 10px;
        color: $backgroundWhite;
        padding: .5rem 1rem;
        background-color: $backgroundGreen;
        border: 0;
    }
}

@media (max-width: $mobileWidth) {
    .thanks-for-order {
        width: 100%;
        padding: 1rem;
    }
}