@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.products-carousel {
    width: 100%;
    p {
        color: $backgroundGreen;
        font-size: 1.2rem;
        font-weight: 450;
        margin-bottom: 20px;
    }
    &__arrow {
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 0 .3rem;
        width: 1.5rem;
        height: 1.5rem;
       
        &::before {
            display: none;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
    .product-card.--carousel {
        margin: 0 1rem;
        width: auto;
        max-width: fit-content;
        // max-width: 200px;
        // max-height: 300px;
        flex-direction: row;

        .product-card__image {
           
        
            img {
                width: auto;
                height: 7rem;
            }
        }
        .product-card__content {
            width: 100%;
            height: auto;
           
            &--description {
               display: none;
            }
            &-info {
               
                &--btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-self: flex-end;
                    padding: .3rem;
                    height: 30px;
                }
    
                &--counter {
                    align-self: flex-end;
                    button {
                        
                        width: 25px;
                        height: 25px;
                  
                    }
                    span {
                        display: flex;
                        // width: 50%;
                        justify-content: center;
                        align-items: center;
                        font-size: 110%;
                        color: $backgroundGreen;
                        font-weight: 500;
                    }
                }
            }
        }

    }
    
}


@media (max-width: $mobileWidth) {
    .banners-carousel {
        width: 100%;
    }
    .products-carousel {
        margin-bottom: 40px;
    }
}