@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.product-card {
    // width: 200px;
    width: 100%;
    // height: 100%;
    margin-bottom: 40px;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    display: grid;
    grid-template-rows: 3fr 1.25fr;
    gap: .5rem;
    &__image {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        //overflow: hidden;
        position: relative;
        border-radius: 10px;
        &--badges {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: row;
            gap: 1rem;
            flex-wrap: wrap;
            padding: .4rem;
            &-badge {
                // width: 1.5rem;
                height: 1.5rem;
                display: flex;
                flex-direction: row;
                gap: .5rem;

                &-img {
                    border-radius: 50%;
                }
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &.--carousel {
        display: flex;
        flex-direction: row;
        .product-card__content-info {
            gap: 0 .5rem;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        // height: 50%;
        &--header {
            color: $backgroundGreen;
            font-weight: 500;
            font-size: 1.2rem;
            display: flex;
            align-items: center;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
            margin-bottom: .6rem;
            // height: 60px;
        }
        &--description {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;  
            // overflow: hidden;
            margin-bottom: .6rem;
        }
        &-info {
            width: 100%;
            display: grid;
            grid-template-areas: "weight btn"
                                 "price btn";
            margin-block:auto;
            
            &--weight {
                grid-area: weight;
                font-size: 1rem;
                color: gray;
            }
            &--price {
                grid-area: price;
                font-weight: bold;
                font-size: 1.25rem;
                color: $backgroundGreen;
            }
            &--btn {
                grid-area: btn;
                border: 0;
                background-color: $backgroundGreen;
                color: $backgroundWhite;
                border-radius: 10px;
                cursor: pointer;
                padding: 0.75rem 0;
            }

            &--counter {
                grid-area: btn;
                width: 100%;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                button {
                    border-radius: 50%;
                    border: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $backgroundGreen;
                    width: 35px;
                    height: 35px;
                    color: $backgroundWhite;
                    font-size: 1rem;
                }
                span {
                    display: flex;
                    // width: 50%;
                    justify-content: center;
                    align-items: center;
                    font-size: 110%;
                    color: $backgroundGreen;
                    font-weight: 500;
                }
            }
        }
    }
}

@media (max-width: $mobileWidth) {
    .product-card {
        width: 100vw;
        * {
            font-size: .8rem;
        }

    }
    .product-card__content--header {
        font-size: 1rem;
    }
    .product-card.--carousel {
        width: auto;
        padding: 0 10px;
    }
    .product-card__content-info--btn {
        padding: .6rem;
    }
    .product-card__content-info--counter {
        button {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}
