.placeholder {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000000;
    position: absolute;
    top: 0;
    left: 0;
    img {
        width: 30%;
        height: auto;
        min-width: 50px;
    }
}