@import './utils/sizes.scss';
@import './utils/colors.scss';
.content {
    // height: calc(100% - $headerHeight - $footerHeight);
    // min-height: calc(100vh - #{$headerHeight} - #{$footerHeight});
    width: 100%;
    background-color: $backgroundGray;
    display: flex;
    flex: 1 1 auto;
    // padding: 2rem;
    margin: 0 auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="number"]:hover,
  input[type="number"]:focus {
    -moz-appearance: number-input;
  }


  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: $backgroundGreen;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
  }


  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  [type="checkbox"]:checked + label,
  [type="checkbox"]:not(:checked) + label
  {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
  }
  [type="checkbox"]:checked + label:before,
  [type="checkbox"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
  }
  [type="checkbox"]:checked + label:after,
  [type="checkbox"]:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: $backgroundGreen;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
  }
  [type="checkbox"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  [type="racheckboxdio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
  }

  a {
    color: inherit;
  }


.modal-we-are-close {
    background-color: white;
    padding: 1rem 1.5rem;
    border-radius: 15px;
    max-width: 500px;

    &__header {
        display: flex;
        justify-content: flex-end;
        div {
            cursor: pointer;
            font-size: 1rem;
        }
    }
    &__content {
        img {
            width: 90px;
            height: auto;
        }
        h2 {
            font-size: 1.2rem;
            font-weight: 500;
        }
        p {
            color: gray;
            font-size: 1rem;
        }
    }
}
  @media (max-width: $mobileWidth) {
        body {
            font-size: 1.6vh;
        }
        .modal-we-are-close {
            width: 80vw;
         }
  }