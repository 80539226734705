@import '../../utils/colors.scss';

.categories-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.2rem;
    margin-top: 30px;
    &__item {
        cursor: pointer;
        font-size: 1.1rem;
        // margin: 0 1vw;
        padding: 5px 20px;
        color: $backgroundGreen;
        &:hover {
            background-color: rgba($backgroundGreen, .5);
            color: $backgroundWhite;
        }
        &.active {
            background-color: $backgroundGreen;
            color: $backgroundWhite;
        }
    }
}

.subcategories-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 30px;
    &__item {
        cursor: pointer;
        font-size: 100%;
        // margin: 0 1vw;
        padding: 5px 20px;
        color: $backgroundGreen;
        &:hover {
            background-color: rgba($backgroundGreen, .5);
            color: $backgroundWhite;
        }
        &.active {
            background-color: $backgroundGreen;
            color: $backgroundWhite;
        }
    }
}