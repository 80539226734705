@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';

.making-order {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.1rem;
    margin-bottom: 1.1rem;
    &__delivery-types {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 1rem;

        &--btn {
            // width: 120px;
            padding: 10px;
            color: $backgroundGreen;
            font-size: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $backgroundWhite;
            cursor: pointer;
            border: 0;
            border-radius: 8px;
            &.--active {
                color: $backgroundWhite;
                background-color: $backgroundGreen;
            }
        }
    }
    .order-terminals {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;

        &__header {
            font-size: 1rem;
            color: $backgroundGreen;
            font-weight: 500;
        }
        &__select {
            max-width: calc(max(80%, 300px));
            

            &>div {
                cursor: pointer;
                border: 0;
                box-shadow: none;
            }
        }
    }
    .order-address {
        display: flex;
        flex-direction: column;
        gap: 10px;
        // width: 300px;
        width: calc(max(80%, 300px));
        &__delivery-check {
            font-size: .9rem;
            color: $backgroundGreen;
        }
        .suggest-address {
            margin-bottom: .25rem;
            font-size: 1rem;
            cursor: pointer;
            color: $backgroundGreen;
            border-bottom: 1px dashed $backgroundGreen;

            &:hover {
                font-weight: 700;
            }
        }
        &__header {
            font-size: 1rem;
            color: $backgroundGreen;
            font-weight: 500;
        }
        &__inputs {
            display: grid;
            grid-template-areas: "street street street street"
                                 "home flat floor entrance"
                                 "list list list list";
            gap: 10px;
            &-input {
                border-radius: 8px;
                border: 0;
                padding: 10px;
                width: 100%;
                &.--street {
                    grid-area: street;
                    font-size: 1rem;
                }
                &.--home {
                    grid-area: home;
                    font-size: 1rem;
                }
                &.--flat {
                    grid-area: flat;
                    font-size: 1rem;
                }
                &.--entrance {
                    grid-area: entrance;
                    font-size: 1rem;
                }
                &-streets-list {
                    grid-area: list;
                }
            }
        }
    }
    &__pick-time {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        &__header {
            font-size: 1rem;
            color: $backgroundGreen;
            font-weight: 500;
        }

        &-types {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 10px;
    
            &--btn {
                padding: 10px;
                color: $backgroundGreen;
                font-size: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $backgroundWhite;
                cursor: pointer;
                border: 0;
                border-radius: 8px;
                &.--active {
                    color: $backgroundWhite;
                    background-color: $backgroundGreen;
                }
            }
        }
        &-inputs {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 15px;
            &--date {
                display: flex;
                flex-direction: column;
                gap: 5px;

                .chosen-date {
                    display: flex;
                    align-items: center;
                    padding: 5px;
                    font-weight: bold;
                    color: $backgroundGreen;
                }
            }
            &--spans {
                span {
                    font-size: .9rem;
                    padding: 0 5px;
                    cursor: pointer;
                    color: $backgroundGreen;
                    font-weight: 600;
                    text-decoration: underline;
                    &.--active {
                        color: black;
                        font-weight: 400;
                        text-decoration: none;
                    }
                }
            }
            &--input-time {
                display: flex;
                flex-direction: column;
                gap: 7px;
            
                label {
                    font-size: .9rem;
                }
                input {
                    width: 100px;
                    border-radius: 8px;
                    border: 0;
                    padding: 5px;
                    font-family: inherit;
                    color: $backgroundGreen;
                }
            }
        }
    }
    .payment-type {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &__header {
            font-size: 1rem;
            color: $backgroundGreen;
            font-weight: 500;
        }
        &__inputs {
            display: flex;
            flex-direction: row;
            gap: 10px;
            flex-wrap: wrap;
        }
        &__change {
            label {
                font-size: 1rem;
            }
            input {
                width: 100px;
                border: 0;
                padding: 5px;
                border-radius: 8px;
                font-size: 1rem;
            }
        }
    }
    &__comment {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
        & label {
            font-size: 1rem;
            color: $backgroundGreen;
            font-weight: 500;
        }
        & textarea {
            resize: none;
            border: 0;
            border-radius: 8px;
            padding: 10px;
            color: $backgroundGreen;
            width: calc(max(80%, 300px));
            height: 100px;
            font-size: 110%;
            outline: none;
        }
    }
}


// @media (min-width: 2000px) {
//     .making-order button {
//       padding: 15px 20px;
//     }
// }